import React, { useState, useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { DefaultButton, Label, useFocusRects } from "@fluentui/react";
import Header from "./Header";
import Progress from "./Progress";
import Fetching from "./Fetching";
import { ExpertiseImageGallery } from "./ExpertiseImageGallery";
import { FaSave } from "react-icons/fa";

import { MdOutlinePictureAsPdf } from "react-icons/md";
import { AiOutlineRollback, AiOutlinePicture, AiOutlineDatabase, AiOutlineWarning } from "react-icons/ai";
import { ExpertiseGed } from "./ExpertiseGed";
import { RapportRubrique } from "./RapportRubrique";

import CONFIG from "./config.js";
import { ExpertiseNote } from "./ExpertiseNote";

import ModeleParam from "./ModeleParam";

/* global Word, require */
/* global Office, require */

export const App = (props) => {
  const [token, settoken] = useState("");
  const [fonction, setfonction] = useState("");
  const [tblData, settblData] = useState([]);
  const [IsAuthenticate, setIsAuthenticate] = useState(false);

  const [enChargement, setEnChargement] = useState(false);
  const [URL, setURL] = useState("");

  const [exId, setExId] = useState(0);
  const [mdId, setMdId] = useState(0);
  const [mfId, setMfId] = useState(0);
  const [fiId, setFiId] = useState(0);
  const [Document, setDocument] = useState(null);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const { title, isOfficeInitialized } = props;


  useEffect(()=>{

    if(URL && URL!='') {
      CONFIG.URL='https://'+URL;
      localStorage.setItem("URL", URL);
      console.log(URL, localStorage.getItem("URL"))
    }
   
  },[URL])

  async function getType() {
    let objet = props.logiciel == "Word" ? Word : Excel;
    console.log(props.logiciel);
    await objet.run(async (context) => {
      console.log(context);
      const properties =
        props.logiciel == "Word" ? context.document.properties.customProperties : context.workbook.properties.custom;
      properties.load("key,type,value");
      await context.sync();
      let i = 0;
      for (i = 0; i < properties.items.length; i++) {
        console.log(properties.items[i].key);
        if (properties.items[i].key == "mdId" && fiId==0) {
          setMdId(properties.items[i].value);
        }
        if (properties.items[i].key == "mfId" && fiId==0) {
          setMfId(properties.items[i].value);
        }
        if (properties.items[i].key == "exId") {
          setExId(properties.items[i].value);
        }
        if (properties.items[i].key == "fiId") {
          setFiId(properties.items[i].value);
          setMdId(0);
          setMfId(0);
        }
      }
    });
  }

  useEffect(() => {
    if (IsAuthenticate && token != "") {
      getExpertise();
    }

    getType();
  }, [token, IsAuthenticate]);

  useEffect(() => {
    if (!Document) return;

    let urlData = "";
    if (fiId > 0) {
      urlData = CONFIG.URL + "/expertise/postContentFichier/" + fiId;
    } else {
      if (mdId > 0) {
        urlData = CONFIG.URL  + "/data/postContentModele/" + mdId;
      }
      if (mfId > 0) {
        urlData = CONFIG.URL  + "/data/postContentModeleFond/" + mfId;
      }
    }
  
    if (urlData != "") {
      var reader = new FileReader();
      reader.readAsDataURL(Document);
      reader.onload = function () {
        try {
          fetch(urlData, { method: "POST", headers: { Token: token }, body: reader.result }).then(
            (res) => {
              if (res.status == 200) {
                setDocument(null);
                setEnChargement(false);
              } else {
                console.log(res);
                setDocument(null);
                setEnChargement(false);
                setErrorMessage("Erreur à l'enregistrement du document");
              }
            },
            (error) => {
              console.log(error);
              setDocument(null);
              setEnChargement(false);
              setErrorMessage("Erreur à l'enregistrement du document");
            }
          );
        } catch (error) {
          console.log(error);
          setDocument(null);
          setEnChargement(false);
          setErrorMessage("Erreur à l'enregistrement du document");
        }
      };
    }
  }, [Document]);

  function getExpertise() {
    if (token == "") return;
    fetch(CONFIG.URL + "/expertise/getAll", { headers: { Token: token } })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          settblData(result);
        },
        (error) => {
          console.log(error);
          settblData([]);
        }
      );
  }

  useEffect(() => {
    console.log("Storage", localStorage);
    console.log("URL", localStorage.getItem("URL"));
    if (localStorage.getItem("URL") && localStorage.getItem("URL") != "") {
      setURL(localStorage.getItem("URL"))
      CONFIG.URL='https://'+localStorage.getItem("URL");
    }
    console.log("Token", localStorage.getItem("token"));
    if (localStorage.getItem("token") && localStorage.getItem("token") != "") {
      console.log(CONFIG.URL + "/auth/getAuth");
      fetch(CONFIG.URL + "/auth/getAuth", { method: "GET", headers: { Token: localStorage.getItem("token") } }).then(
        (res) => {
          console.log(res.status)
          if (res.status != 200) {
            localStorage.setItem("token", "");
            //      login();
          } else {
            res.json().then(
              (texte) => {
                console.log(texte);
                if (texte != "OK") {
                  localStorage.setItem("token", "");
                } else {
                  settoken(localStorage.getItem("token"));
                  setIsAuthenticate(true);
                }
              },
              (error) => {
                console.log(error)
                localStorage.setItem("token", "");
              }
            );
          }
        },
        (error) => {
          console.log(error)
          localStorage.setItem("token", "");
          //    login();
        }
      );
    } else {
      //  login();
    }
  }, []);

  function getDocument() {
    setEnChargement(true);
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 }, function (result) {
      if (result.status == "succeeded") {
        // If the getFileAsync call succeeded, then
        // result.value will return a valid File Object.
        var myFile = result.value;
        var sliceCount = myFile.sliceCount;
        var slicesReceived = 0,
          gotAllSlices = true,
          docdataSlices = [];
        console.log("File size:" + myFile.size + " #Slices: " + sliceCount);

        // Get the file slices.
        getSliceAsync(myFile, 0, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
      } else {
        setEnChargement(false);
        console.log("Error:", result.error.message);
      }
    });
  }

  function getSliceAsync(file, nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived) {
    file.getSliceAsync(nextSlice, function (sliceResult) {
      if (sliceResult.status == "succeeded") {
        if (!gotAllSlices) {
          // Failed to get all slices, no need to continue.
          setEnChargement(false);
          return;
        }
        docdataSlices[sliceResult.value.index] = sliceResult.value.data;
        if (++slicesReceived == sliceCount) {
          file.closeAsync();
          onGotAllSlices(docdataSlices);
        } else {
          getSliceAsync(file, ++nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
        }
      } else {
        setEnChargement(false);
        gotAllSlices = false;
        file.closeAsync();
        console.log("getSliceAsync Error:", sliceResult.error.message);
      }
    });
  }

  function onGotAllSlices(docdataSlices) {
    var docdata = [];
    for (var i = 0; i < docdataSlices.length; i++) {
      docdata = docdata.concat(docdataSlices[i]);
    }

    var fileContent = new String();
    for (var j = 0; j < docdata.length; j++) {
      fileContent += String.fromCharCode(docdata[j]);
    }

    setDocument(new File([new Uint8Array(docdata)], "test.docx"));

    /*
           var a = document.createElement('a');
           a.download = 'test.docx';
           a.href = window.URL.createObjectURL(file);
           a.click();*/
  }

  function login() {
    var dialog;
   
     Office.context.ui.displayDialogAsync(window.location.origin+'/login.html?URL='+URL,function (asyncResult) {
    // Office.context.ui.displayDialogAsync('https://localhost:3000/login.html?URL='+URL,function (asyncResult) {
  //  Office.context.ui.displayDialogAsync("https://addin.gexcloud.fr/login.html?URL="+URL, function (asyncResult) {
      console.log(asyncResult);
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        showNotification((asyncResult.error.code = ": " + asyncResult.error.message));
      } else {
        dialog = asyncResult.value;

        dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (arg) {
          setIsAuthenticate(true);
          settoken(arg.message);
          localStorage.setItem("token", arg.message);
          getExpertise();
          dialog.close();
        });
      }
    });
  }

  function logout() {
    setIsAuthenticate(false);
    settoken("");
    localStorage.setItem("token", "");
  }

  function getFonction() {
    console.log(fonction);
    switch (fonction) {
      case "Photos":
        return (
          <>
            <ExpertiseImageGallery token={token} exid={exId}></ExpertiseImageGallery>
          </>
        );
      case "Pieces":
        return (
          <>
            <ExpertiseGed modeSelection={true} complet={true} sansTitre={true} token={token} exid={exId}></ExpertiseGed>
          </>
        );
      case "Rubrique":
        return (
          <>
            <RapportRubrique token={token} exid={exId}></RapportRubrique>
          </>
        );
      case "Note":
        return (
          <>
            <ExpertiseNote token={token} exid={exId}></ExpertiseNote>
          </>
        );
      default:
        return "";
    }
  }

  function getPageRapport() {
    return (
      <>
        {exId <= 0 ? (
          <div className="m-2">
            <label className="form-label">
              <h4>Sélectionnez une expertise</h4>
            </label>
            <select className="form-control" defaultValue="0" value={exId} onChange={(e) => setExId(e.target.value)}>
            <option value={0} key={0}>{"<< Sélectionnez une expertise >>"}</option>
              {tblData
                ? tblData.map((element, i) => (
                    <option value={element.exId} key={i}>
                      {element.exNumero + " - " + element.exNom}
                    </option>
                  ))
                : null}
            </select>
          </div>
        ) : (
          <>
            <div className="d-flex align-content-center justify-content-center flex-wrap mb-2">
              {props.logiciel != "Excel" ? (
                <>
                  <button
                    onClick={() => setfonction("Photos")}
                    title="Insérer des photos"
                    className="btn mt-1 btn-primary mx-1"
                    href="#"
                  >
                    <AiOutlinePicture /> Photos
                  </button>
                  <button
                    onClick={() => setfonction("Pieces")}
                    title="Insérer des pièces"
                    className="btn mt-1 btn-primary mx-1"
                    href="#"
                  >
                    <MdOutlinePictureAsPdf /> Bibliothèque
                  </button>
                  <button
                    onClick={() => setfonction("Rubrique")}
                    title="Insérer une rubrique"
                    className="btn mt-1 btn-primary mx-1"
                    href="#"
                  >
                    <AiOutlineDatabase /> Rubrique
                  </button>
                  <button
                    onClick={() => setfonction("Note")}
                    title="Insérer une note"
                    className="btn mt-1 btn-primary mx-1"
                    href="#"
                  >
                    <AiOutlineDatabase /> Note
                  </button>
                </>
              ) : (
                ""
              )}
              <button
                style={{ display: fiId > 0 ? "" : "none" }}
                onClick={() => getDocument()}
                title="Sauvegarder"
                className="mx-1 mt-1 btn btn-primary"
              >
                <FaSave></FaSave> Sauvegarde
              </button>
              {props.logiciel != "Excel" ? (
                <button onClick={() => setExId(0)} title="Changer d'expertise" className="mx-1 mt-1 btn btn-secondary">
                  <AiOutlineRollback></AiOutlineRollback> Changer d'expertise
                </button>
              ) : (
                ""
              )}
            </div>
            {getFonction()}
          </>
        )}
      </>
    );
  }

  function getPageParam() {
    return <ModeleParam URL={URL} getDocument={getDocument} logiciel={props.logiciel} token={token} mfId={mfId} mdId={mdId}></ModeleParam>;
  }

  if (!isOfficeInitialized) {
    return (
      <Progress
        title={title}
        logo={require("./../../../assets/logo-filled.png")}
        message="Merci de recharger le composant"
      />
    );
  }

  if (!IsAuthenticate) {
    return (
      <div>
        <Header logo={require("./../../../assets/logo-filled.png")} title={props.title} message="GEX Online" />
        <div>
          <div className="h5 p-2">Bienvenue sur le plugins Office de GEX Online.</div>
          <div className="p-2">
            GEX est la solution de gestion des expertises judiciaires et privées disponible sur le site{" "}
            <a target="_blank" href={"https://"+URL}>
              gexcloud.fr
            </a>
          </div>
          <div className="p-2">
            Utiliser le plugins Office pour insérer photos, rubriques et pièces issues de vos expertises GEX.
          </div>
        </div>
        <div className="GexContent d-flex">
          <select onChange={(e) => setURL(e.target.value)} type="text" value={URL} className="form-control">
            <option value={""}>Choix d'un serveur</option>
            
              {window.location.origin=='https://localhost:3000' ? 
                 <option value={"test.gexcloud.fr"}>Pre prod</option>: null}
            <option value={"s1.gexcloud.fr"}>Serveur 1</option>
            <option value={"s2.gexcloud.fr"}>Serveur 2</option>
            <option value={"s3.gexcloud.fr"}>Serveur 3</option>
            <option value={"s4.gexcloud.fr"}>Serveur 4</option>
            <option value={"s5.gexcloud.fr"}>Serveur 5</option>
            <option value={"s6.gexcloud.fr"}>Serveur 6</option>
            <option value={"s7.gexcloud.fr"}>Serveur 7</option>
            <option value={"s8.gexcloud.fr"}>Serveur 8</option>
            <option value={"s9.gexcloud.fr"}>Serveur 9</option>
          </select>

          {URL && URL != "" ? (
            <DefaultButton style={{minWidth:'150px'}} className="ms-welcome__action mx-2" iconProps={{ iconName: "ChevronRight" }} onClick={login}>
              Se connecter
            </DefaultButton>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div className="ms-welcome">
      {ErrorMessage ? (
        <>
          <div className="modal fade show" style={{ display: "block" }}>
            <div style={{ overflowY: "auto" }} className={"modal-dialog modal-lg"}>
              <div className="modal-content ">
                <div className={"modal-header modal-colored-header bg-primary"}>
                  <h4>Erreur</h4>
                  <button
                    onClick={() => setErrorMessage(null)}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body modal-tailleFenetre">
                  <p>
                    <AiOutlineWarning style={{ width: "50px" }}></AiOutlineWarning>
                    {ErrorMessage}
                  </p>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={() => setErrorMessage(null)}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      ) : null}
      {enChargement ? <Fetching></Fetching> : null}
      <Header logo={require("./../../../assets/logo-filled.png")} title={props.title} message="GEX Online" />

      <div className="GexContent">
        <DefaultButton className="ms-welcome__action" iconProps={{ iconName: "ChevronRight" }} onClick={logout}>
          Deconnexion
        </DefaultButton>

        {((mdId && mdId > 0 || mfId && mfId > 0 ) && (!fiId || fiId <= 0)) || props.logiciel == "Excel" ? getPageParam() : getPageRapport()}
      </div>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};
