import React, { useEffect, useState } from 'react';
import CONFIG from './config.js'
import ChampPersoFiche from './ChampPersoFiche';
import { insertText } from './GexWord'
import { MdAdd, MdArrowDownward, MdDelete, MdRefresh } from 'react-icons/md';

export default function ModeleParam(props) {

    const [token, settoken] = useState(props.token);
    const [mdId, setmdId] = useState(props.mdId);
    const [mfId, setmfId] = useState(props.mfId);
    const [enFetching, setenFetching] = useState(false);
    const [tblChamp, settblChamp] = useState([]);
    const [tblRubrique, settblRubrique] = useState([]);
    const [popupChampPerso, setpopupChampPerso] = useState(false)
    const [CategorieEnCours, setCategorieEnCours] = useState(-1)
    const [ChampPerso, setChampPerso] = useState("")
    const [tblCategorie, settblCategorie] = useState([]);
    const [tblChampPersonnalise, settblChampPersonnalise] = useState([]);

    useEffect(() => {
        if (token != '') {
            getChampFusion();

        }

    }, [token])

    function getPageParamRubrique() {
        var dialog;
        //Office.context.ui.displayDialogAsync('https://localhost:3000/rapportRubrique.html?auth='+token,
        Office.context.ui.displayDialogAsync('https://addin.gexcloud.fr/rapportRubrique.html?auth=' + token+"&URL="+props.URL,
            function (asyncResult) {

            });


    }


    function getChampFusion() {
        if (token == '') return;
        fetch(CONFIG.URL + '/fusion/getChampListe/0', { headers: { 'Token': token } })
            .then(res => res.json())
            .then(
                (result) => {
                    let data = JSON.parse(result);
                    settblCategorie(data[0]);
                    settblChamp(data[1]);
                    setCategorieEnCours(0);

                },
                (error) => {
                    console.log(error);
                }
            )
        if(mdId && mdId>0) {
        fetch(CONFIG.URL + '/fusion/getChampPerso/' + mdId, { headers: { 'Token': token } })
            .then(res => res.json())
            .then(
                (result) => {
                    let data = JSON.parse(result);
                    settblChampPersonnalise(data);
                },
                (error) => {
                    console.log(error);
                }
            )
        }
        getRapportRubrique();
    }

    function getRapportRubrique() {
        fetch(CONFIG.URL + '/data/getRapportrubriques', { headers: { 'Token': token } })

            .then(res => res.json())
            .then(
                (result) => {
                    let data = JSON.parse(result);
                    settblRubrique(data);



                },
                (error) => {
                    console.log(error);
                }
            )
    }

    function deleteChampPerso() {
        let maNom = ChampPerso.replace('ATTRIBUT.', '');
        let i = 0;
        for (i = 0; i < tblChampPersonnalise.length; i++) {
            if (tblChampPersonnalise[i].maNom == maNom) {
                fetch(CONFIG.URL + '/fusion/deleteChampPerso/' + tblChampPersonnalise[i].maId, { headers: { 'Token': props.token } })

                    .then(res => {
                        if (res.status == 200) {
                            settblChampPersonnalise(tblChampPersonnalise.filter(ele => ele.maNom != maNom))

                        }

                    });

            }
        }
    }

    return <div className='row  m-2' >
        <label className='mt-2'><h4>Ajout de champ de fusion</h4></label>
        <div style={{ display: (tblCategorie.length > 1 ? '' : 'none') }} className="col-sm-6 ">
            <label className="form-label">Catégorie </label>
            <select onChange={(e) => setCategorieEnCours(e.target.value)} type="text" value={CategorieEnCours || ""} className="form-control">
                {tblCategorie.map((element, i) => <option key={i} value={i}>{element.intitule}</option>)}

            </select>
        </div>
        <div className="col-sm-6 ">
            {CategorieEnCours >= 0 ?
                <><label className="form-label">Champ de fusion</label>
                    <select onChange={(e) => insertText('[%' + e.target.value + '%]', props.logiciel)} type="text" value={""} className="form-control">
                        <option key={-1} value={""}>{"<< Selectionnez un champ >>"}</option>
                        {tblChamp.filter(ele => tblCategorie[CategorieEnCours].fichier.indexOf(ele.fichier) >= 0).map((element, i) => <option key={i} value={tblCategorie[CategorieEnCours].intitule + '.' + element.codeChamp}>{element.intitule}</option>)}

                    </select></> : null}
        </div>
        {mdId>0 ? 
        <>
        <hr className='my-2'></hr>
        <label className=''><h4>Champ de personnalisé</h4></label>

        <div className="col-sm-12 ">

            <><label className="form-label">Insérer un champ</label>
                <div className='d-flex'>
                    <select onChange={(e) => { setChampPerso(e.target.value) }} type="text" value={ChampPerso || ''} className="form-control">
                        <option key={-1} value={""}>{"<< Selectionnez un champ >>"}</option>
                        {tblChampPersonnalise.map((element, i) => <option key={i} value={"ATTRIBUT" + '.' + element.maNom}>{element.maIntitule}</option>)}
                    </select>
                    {ChampPerso != "" ? <button onClick={() => insertText('[%' + ChampPerso + '%]', props.logiciel)} title='Insérer le champ personnalisé' className='mx-1 btn btn-primary'><MdArrowDownward></MdArrowDownward></button> : null}
                    <button onClick={() => setpopupChampPerso(true)} title='Créer un champ personnalisé' className='mx-1 btn btn-primary'><MdAdd></MdAdd></button>
                    {ChampPerso != "" ? <button onClick={() => deleteChampPerso()} title='Supprimer le champ personnalisé' className='mx-1 btn btn-secondary'><MdDelete></MdDelete></button> : null}
                </div>

            </>
        </div>
        {props.logiciel == "Word" ? <>
            <hr className='my-2'></hr>
            <label className=''><h4>Ajout de rubrique</h4></label>
            <div className="col-sm-12 ">

                <><label className="form-label">Rubrique</label>
                    <div className='d-flex'>
                        <select onChange={(e) => insertText('{%' + e.target.value + '%}', props.logiciel)} type="text" value={""} className="form-control">
                            <option key={-1} value={""}>{"<< Selectionnez un champ >>"}</option>
                            {tblRubrique.map((element, i) => <option key={i} value={element.ruCode}>{element.ruNom + " (" + element.ruCode + ")"}</option>)}

                        </select>
                        <button onClick={getPageParamRubrique} title='Paramétrage' className='mx-1 btn btn-primary'><MdAdd></MdAdd></button>
                        <button onClick={getRapportRubrique} title='Mise à jour' className='mx-1 btn btn-primary'><MdRefresh></MdRefresh></button>
                    </div>
                </>
            </div>
        </>
        :null }
          </>  : ""}
        <button onClick={() => props.getDocument()} className='mt-2 btn btn-primary'>Sauvegarder le modèle</button>
        {popupChampPerso ? <ChampPersoFiche onAdd={(ele) => { insertText('[%ATTRIBUT.' + ele.maNom + '%]', props.logiciel); settblChampPersonnalise([...tblChampPersonnalise, ele]) }} token={token} mdId={mdId} onClose={() => setpopupChampPerso(false)}></ChampPersoFiche> : null}

    </div>
}