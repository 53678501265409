import React, { useEffect, useState } from 'react';
import CONFIG from './config.js'
import Fetching from './Fetching';

export default function ChampPersoFiche(props) {
   
    const [SaisieEnCours, setSaisieEnCours] = useState({ maNom: '', maIntitule: '', maType: 1 });
    const [enFetching, setenFetching] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');

    function submitForm() {
        if(SaisieEnCours.maNom=='') {
            seterrorMessage('Le nom du champ ne peut etre vide');
            return false;
        }
        if(SaisieEnCours.maIntitule=='') {
            seterrorMessage("L'intitule du champ ne peut etre vide");
            return false;
        }
        fetch(CONFIG.URL + '/fusion/postChampPerso', { headers: { 'Token': props.token }, method: "POST", body: JSON.stringify({ ...SaisieEnCours, mdId: props.mdId }) })

            .then(res => {
                if (res.status != 200) {
                    res.text().then((message) =>
                        seterrorMessage(message)
                     
                   )
                    setenFetching(false);
                } else {
                    //addToast('Champ ajoute avec succès', { appearance: 'success', autoDismiss: true })
                    res.json().then((message) => {
                        props.onAdd(message);
                        props.onClose();
                    }
                    )

                }
                setenFetching(false);
            });
        setenFetching(true);



    }

    return <div className="modal fade show" style={{ display: 'block' }} >
        <div style={{ overflowY: 'auto' }} className={"modal-dialog modal-md"}>
            <div className="modal-content ">
                <div className="p-2 modal-colored-header bg-primary d-flex justify-content-between">
                    <h4>Création d'un champ personnalisé</h4>
                    <button onClick={props.onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                </div>
                <div className="modal-body" >
                    <div className='row' >
                        <div className="col-lg-12 ">
                            <label className="form-label">Nom système du champ (sans espace, sans caractère spéciaux)</label>
                            <input onChange={(e) => setSaisieEnCours({ ...SaisieEnCours, maNom: e.target.value.toUpperCase() })} type="text" value={SaisieEnCours.maNom || ""} className="form-control text-uppercase"></input>
                        </div>
                        <div className="col-lg-12 ">
                            <label className="form-label">Libellé du champ</label>
                            <input onChange={(e) => setSaisieEnCours({ ...SaisieEnCours, maIntitule: e.target.value })} type="text" value={SaisieEnCours.maIntitule || ""} className="form-control"></input>
                        </div>
                        <div className="col-lg-12 ">
                            <label className="form-label">Format</label>
                            <select onChange={(e) => setSaisieEnCours({ ...SaisieEnCours, maType: e.target.value })} type="text" value={SaisieEnCours.maType} className="form-control">
                                <option value={1}>Texte</option>
                                <option value={2}>Date</option>
                                <option value={9}>Date longue</option>
                                <option value={3}>Heure</option>
                                <option value={4}>Nombre entier</option>
                                <option value={5}>Nombre décimal</option>

                            </select>
                        </div>
                    </div>
                    {errorMessage!="" ? <label className='text-danger'>{errorMessage}</label> : null}
                </div>
                <div className="modal-footer">
                    <><button onClick={props.onClose} type="button" className="ml-auto p-2 btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                        <button onClick={() => submitForm()} id="Enregistrer" type="submit" className="p-2 btn btn-primary">Envoyer</button>
                    </>
                </div>

            </div>
            {enFetching ? <Fetching /> : null}

        </div>

    </div >


}