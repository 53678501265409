
import React, { useState, useEffect, useRef } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { insertDocx } from './GexWord';
import { AiFillEye } from 'react-icons/ai';
import CONFIG from './config.js'
import Fetching from './Fetching';


export const ExpertiseNote = (props) => {
    const [tblData, settblData] = useState([]);
    const [tblType, settblType] = useState([]);
    const [Apercu, setApercu] = useState(null);

    const [IdEnCours, setIdEnCours] = useState(0);
    const [enFetching, setenFetching] = useState(false)
    const [Filter, setFilter] = useState(0);


    useEffect(() => {
        if (tblData.length <= 0) {
            fetch(CONFIG.URL + '/expertise/getExpertiseNoteAll/' + props.exid, { headers: { 'Token': props.token } })

                .then(res => res.json())
                .then(
                    (result) => {
                        settblData(JSON.parse(result));
                    },
                    (error) => {
                        //settblData([]);
                    }
                )
        }
        if (tblType.length <= 0) {
            fetch(CONFIG.URL + '/data/getData?fichier=TypeNote', { headers: { 'Token': props.token } })

                .then(res => res.json())
                .then(
                    (result) => {

                        settblType(JSON.parse(result));
                        console.log(JSON.parse(result));
                    },
                    (error) => {
                        //settblData([]);
                    }
                )
        }
    });

   
    function getNote(element) {
        setenFetching(true);
        fetch(CONFIG.URL + '/fusion/getExpertiseNote/DOCX/' + element.noId, { headers: { 'Token': props.token } })
            .then(res => res.blob())
            .then(
                (rawData) => {
                    console.log(rawData)
                    const reader = new FileReader();
                    reader.onload = () => {
                        setenFetching(false);
                        insertDocx(reader.result);
                    };
                    reader.readAsDataURL(rawData);
                },
                (error) => {
                    setenFetching(false);
                    //settblData([]);
                }
            )
    }
    
    function getNoteApercu(element) {
        setenFetching(true);
        fetch(CONFIG.URL + '/expertise/getExpertiseNote/' + element.noId, { headers: { 'Token': props.token } })

            .then(res => res.json())
            .then(
                (result) => {
                    setApercu({ __html: JSON.parse(result).noNote });
                    setenFetching(false);
                },
                (error) => {
                    setenFetching(false);
                    //settblData([]);
                }
            )


    }

    function trclick(e) {

        setIdEnCours(e.target.parentElement.id);
        if (e.detail >= 2) {
            getNote(tblData[e.target.parentElement.id]);
        }
    }

    if (Apercu) {
        return <>
            <button className='btn btn-secondary m-2' onClick={() => { setApercu(null); getNote(tblData[IdEnCours]); }}>Insérer</button>
            <button className='btn btn-secondary m-2' onClick={() => setApercu(null)}>Annuler</button>
            <div dangerouslySetInnerHTML={Apercu} id="Apercu"></div>
        </>;
    }

    if (tblData) {
        return <>
            <div className="p-2 col-lg-12">
            {enFetching ? <div>
            <Fetching></Fetching>
        </div> : null}
                <div className="d-flex align-items-center">
                    <div className="col-lg-3 ">
                        <label className="form-label">Type de note</label>
                        <select onChange={(e) => setFilter(e.target.value)} type="text" value={Filter || 0} className="form-control">
                            <option value={0}>{"<< Ne pas filtrer >>"}</option>
                            {tblType.map((element) => <option key={element.cle} value={element.cle}>{element.valeur}</option>)}
                        </select>
                    </div>

                </div>
                <table className="mt-2 table  table-sm" id="main-table">

                    <thead className="table-dark">
                        <tr>
                            <th style={{ width: '5%' }}><AiFillEye /></th>
                            <th style={{ width: '45%' }}>Titre</th>
                            <th style={{ width: '50%' }}>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tblData.map((element, i) => {
                                if (Filter > 0 && element.noType != Filter) return null;

                                return (
                                    <tr className={(IdEnCours == i ? ' ligneEnCours' : '')} onMouseDown={trclick} key={"Dt" + i} id={i}>
                                        <td><a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setIdEnCours(i);
                                            getNoteApercu(element)

                                        }}><AiFillEye /></a></td>
                                        <td>{element.noType}</td>
                                        <td>{element.noTitre}</td>
                                    </tr>
                                )


                            }
                            )
                        }
                    </tbody>
                </table >

            </div>




        </>;
    } else {
        return <FaSpinner></FaSpinner>;
    }

}


