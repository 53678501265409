import React, { useState, useEffect, Fragment, useRef } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { GrCheckboxSelected, GrAddCircle, GrRefresh } from 'react-icons/gr';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
import { FcFolder, FcFile } from 'react-icons/fc';
import PdfViewer from './PdfViewer';
import moment from 'moment';
import bereich from 'bereich';

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { insertImage, insertDocx, insertText } from './GexWord';
import CONFIG from './config'
import Fetching from './Fetching';

export const ExpertiseGed = (props) => {


    const [Loading, setLoading] = useState("");
    const [tblData, settblData] = useState([]);
    const [Menu, setMenu] = useState(null);
    const [tblFichierEnCours, settblFichierEnCours] = useState([]);
    const [tblDossierCharge, settblDossierCharge] = useState([]);
    const [fenSaisie, setfenSaisie] = useState({});
    const [dossierEnCours, setdossierEnCours] = useState({});

    const [tblFichierSelect, settblFichierSelect] = useState([]);
    const [tblDossierSelect, settblDossierSelect] = useState([]);

    const [ApercuFichierPDF, setApercuFichierPDF] = useState('');
    const [ApercuFichierImage, setApercuFichierImage] = useState(null);
    const [AffichageApercu, setAffichageApercu] = useState(false);
    const [enFetching, setenFetching] = useState(false)
    const [avecTitre, setAvecTitre] = useState(false)
    const [StyleTitre, setStyleTitre] = useState('')

    const [mobile, setMobile] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null);

    const refFichier = useRef();

    useEffect(() => {
        if (tblData.length <= 0) getData();

        if (Office.context.diagnostics.platform == 'iOS' || Office.context.diagnostics.platform == 'Android') {
            setMobile(true)
            console.log('mobile');
        }
    }, []);

    useEffect(() => {
        if (!selectedFile) return;
        if (selectedFile.type != 'application/pdf') {
            refFichier.current.value = ""
            return
        }
        var reader = new FileReader();
        reader.readAsDataURL(selectedFile);

        reader.onload = function () {
            console.log(reader.result)
            setApercuFichierPDF(reader.result);

        };
        refFichier.current.value = ""
    }, [selectedFile]);

    function trclick(dossier, fichier, e) {
        console.log(e);
        if (props.onSelect) {
            props.onSelect(fichier ? fichier.fiId : 0, dossier ? dossier.dsId : 0);
        }
        if (dossier && dossier.dsId) {
            setdossierEnCours(dossier);
            settblFichierEnCours([])

        } else {
            if (fichier) {
                let multiSelection = e.ctrlKey;
                if (multiSelection || (tblFichierEnCours.find(element => element.fiId == fichier.fiId) && e.button == 2)) {
                    if (!(tblFichierEnCours.find(element => element.fiId == fichier.fiId))) {
                        settblFichierEnCours([...tblFichierEnCours, fichier]);
                    } else {
                        if (e.button == 0) {
                            var index = tblFichierEnCours.indexOf(fichier);
                            if (index !== -1) {
                                settblFichierEnCours(tblFichierEnCours.filter((ele, i) => i !== index))
                            }

                        }
                    }
                    setdossierEnCours(null);
                } else {
                    settblFichierEnCours([fichier]);
                    setdossierEnCours(null);
                }
            }
        }

    }

    function getPage(entIndice, pdf) {

        pdf.getPage(entIndice).then(page => {

            let scale = 1.5;
            console.log("Printing " + entIndice);
            var viewport = page.getViewport({ scale });
            var canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
            var renderContext = { canvasContext: ctx, viewport: viewport };

            canvas.height = viewport.height;
            canvas.width = viewport.width;
            page.render(renderContext).promise.then(() => {
                insertImage(canvas.toDataURL('image/jpeg'));
                if (entIndice > 0) {
                    entIndice--;
                    getPage(entIndice, pdf)
                }
                if (entIndice == 0) {
                    setenFetching(false);
                }
            })
        })

    }

    async function lecturePiece(file) {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = function () {
                return resolve(fileReader.result);
            }
            fileReader.readAsDataURL(file);
        })
    }


    async function readPDFPages(pdffile) {
        console.log(pdffile)
        const response = await pdfjsLib.getDocument(pdffile);

        let pdf = await response.promise;

        const numPages = pdf.numPages;

        const pageNumbers = Array.from(bereich(1, numPages));
        // Start reading all pages 1...numPages

        const promises = pageNumbers.map(pageNo => pdf.getPage(pageNo));

        // Wait until all pages have been read
        const pages = await Promise.all(promises);
        // You can do something with pages here.
        console.log(pages)
        return pages;
    }



    async function insertFile() {
        let i = 0;
        setenFetching(true);


        console.log(tblFichierSelect);
        for (i = tblFichierSelect.length - 1; i >= 0; i--) {


            switch (tblFichierSelect[i].fichiertype.ftExtention) {
                case "PDF":
                    const tblPage = await readPDFPages(CONFIG.URL + '/expertise/getFichier/' + tblFichierSelect[i].fiId + "?auth=" + props.token)
                    let entIndice = 0

                    for (entIndice = tblPage.length - 1; entIndice >= 0; entIndice--) {
                        let page = tblPage[entIndice]
                        let scale=1.5
                        if(entIndice==0 && avecTitre) {
                            scale = 0.95;
                        }
                       
                        console.log("Printing " + entIndice);
                        var viewport = page.getViewport({ scale });
                        var canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
                        var renderContext = { canvasContext: ctx, viewport: viewport };

                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        let pageRender = await page.render(renderContext);
                        let pageRenderPromise = await pageRender.promise;
                       
                        await insertImage(canvas.toDataURL('image/jpeg'));




                    }


                    /*
                                        pdfjsLib.getDocument(CONFIG.URL + '/expertise/getFichier/' + tblFichierSelect[i].fiId + "?auth=" + props.token).promise.then(pdf => {
                                            getPage(pdf.numPages, pdf)
                                        })
                    */

                    break;
                case "DOCX":
                    const responseDocx = await fetch(CONFIG.URL + '/expertise/getFichier/' + tblFichierSelect[i].fiId, { headers: { 'Token': props.token } });
                    let rawDataDocx = await responseDocx.blob();
                    let dataURLdocx = await readFileAsDataURL(rawDataDocx)
                    await insertDocx(dataURLdocx);
                    /*
                                        fetch(CONFIG.URL + '/expertise/getFichier/' + tblFichierSelect[i].fiId, { headers: { 'Token': props.token } })
                                            .then(res => res.blob())
                                            .then(rawData => {
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    insertDocx(reader.result)
                                                    setenFetching(false);
                                                };
                                                reader.readAsDataURL(rawData);
                                            }
                                            );
                                            */
                    break;
                case "JPG":
                case "JPEG":
                case "PNG":
                case "BMP":
                    const response = await fetch(CONFIG.URL + '/expertise/getFichier/' + tblFichierSelect[i].fiId, { headers: { 'Token': props.token } });
                    let rawData = await response.blob();
                    let dataURL = await readFileAsDataURL(rawData)
                    await insertImage(dataURL, tblFichierSelect[i], false, false)

                    /*
                        fetch(CONFIG.URL + '/expertise/getFichier/' + tblFichierSelect[i].fiId, { headers: { 'Token': props.token } })
                            .then(res => res.blob())
                            .then(rawData => {
                                const reader = new FileReader();
                                reader.onload = () => {
                                    insertImage(reader.result, tblFichierSelect[i], false, false)
                                    setenFetching(false);
                                };
                                reader.readAsDataURL(rawData);
                            }
                            );
                            */
                    break;
            }
            if (avecTitre) {
                let texteTitre = tblFichierSelect[i].fiNom;
                if (tblFichierSelect[i].tiers && tblFichierSelect[i].tiers.ctId && tblFichierSelect[i].ctId > 0) {
                    if (tblFichierSelect[i].tiers.ctEstPro) {
                        texteTitre += " de " + tblFichierSelect[i].tiers.ctIntitule
                    } else {
                        texteTitre += " de " + tblFichierSelect[i].tiers.ctNom + " " + tblFichierSelect[i].tiers.ctPrenom
                    }
                }
                await insertText(texteTitre, "Word", Word.InsertLocation.start, StyleTitre)
                if(i != 0)await insertText('\n', "Word", Word.InsertLocation.start)
            }


        }
        setenFetching(false);
    }

    async function readFileAsDataURL(file) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });

        console.log(result_base64); // aGV5IHRoZXJl...

        return result_base64;
    }

    function fctMiseAjourTblDossier(tblDossier, dsId, tblDossierACharger) {
        let i = 0;
        for (i = 0; i < tblDossier.length; i++) {
            if (tblDossier[i].dsId == dsId) {
                tblDossier[i].sousdossiers = tblDossierACharger;
                return true;
            } else {
                if (fctMiseAjourTblDossier(tblDossier[i].sousdossiers, dsId, tblDossierACharger)) return true;
            }

        }
    }



    function getDataEnfant(dossier, i) {
        fetch(CONFIG.URL + '/expertise/getDossier/' + props.exid + '/' + dossier.dsId, { headers: { 'Token': props.token } })
            .then(res => res.json())
            .then(
                (result) => {
                    let tblDossierLocal = tblData;

                    if (fctMiseAjourTblDossier(tblDossierLocal, dossier.dsId, JSON.parse(result))) {
                        settblData(tblDossierLocal);
                    }

                    //console.log(dossier.dsNom);
                    //this.setState({ dossier: dossierLocal, Loading: true });
                    //settblDossierCharge([...tblDossierCharge,dossier.dsId])
                },
                (error) => {
                    console.log(error);
                }
            )

    }



    function getData() {

        fetch(CONFIG.URL + '/expertise/getDossier/' + props.exid + '/0', { headers: { 'Token': props.token } })

            .then(res => res.json())
            .then(
                (result) => {
                    console.log(JSON.parse(result))
                    settblData(JSON.parse(result));
                    setLoading(true);
                },
                (error) => {
                    settblData(null);
                    setLoading(true);
                }
            )
    }


    if (!Loading) {
        return (<div key={"SPNN" + props.exid} className="">
            <FaSpinner className="spinner" />
        </div>)
    }

    function pcdSelectFichier(fichier, select) {
        if (select) {
            if (tblFichierSelect.indexOf(fichier) < 0) {
                settblFichierSelect([...tblFichierSelect, fichier]);
            }
        } else {
            let index = tblFichierSelect.indexOf(fichier);
            if (index !== -1) {
                settblFichierSelect(tblFichierSelect.filter((ele, i) => i !== index))

            }
        }


    }

    function fctGetDossier(dossier, tblDossier, tblFichier, select) {
        let i = 0;
        for (i = 0; i < dossier.sousdossiers.length; i++) {
            if (!select || tblDossierSelect.indexOf(dossier.sousdossiers[i]) < 0) {
                tblDossier.push(dossier.sousdossiers[i])
            }

            fctGetDossier(dossier.sousdossiers[i], tblDossier, tblFichier, select);
        }
        for (i = 0; i < dossier.fichiers.length; i++) {
            if (!select || tblDossierSelect.indexOf(dossier.fichiers[i]) < 0) {

                tblFichier.push(dossier.fichiers[i])
            }
        }
    }


    function pcdSelectDossier(dossier, select) {
        let tblDossier = [dossier];
        let tblFichier = [];

        fctGetDossier(dossier, tblDossier, tblFichier, select);

        if (select) {
            settblDossierSelect([...tblDossierSelect, ...tblDossier]);
            settblFichierSelect([...tblFichierSelect, ...tblFichier]);

        } else {

            settblDossierSelect(tblDossierSelect.filter((ele, i) => tblDossier.indexOf(ele) < 0))
            settblFichierSelect(tblFichierSelect.filter((ele, i) => tblFichier.indexOf(ele) < 0))
        }


    }



    function getFichier(fichier, niveau) {

        return (
            <tr className={tblFichierEnCours.find(element => element.fiId == fichier.fiId) ? 'ligneSelect ligneEnCours' : ''} onMouseDown={(e) => trclick(null, fichier, e)} key={"FI" + fichier.fiId} id={fichier.fiId}>
                {props.modeSelection ? <td><input onChange={(e) => pcdSelectFichier(fichier, e.target.checked)} type="checkbox" checked={tblFichierSelect.find(ele => ele.fiId == fichier.fiId) ? "checked" : ""}></input></td> : null}
                <td style={{ paddingLeft: 40 * niveau + 'px' }}><FcFile /><a href="#" onClick={(e) => {
                    if (mobile) return;
                    if (fichier.fichiertype.ftExtention == "PDF") {
                        e.preventDefault();
                        setApercuFichierPDF(CONFIG.URL + '/expertise/getFichier/' + fichier.fiId + "?auth=" + props.token)
                    }
                    if (fichier.fichiertype.ftExtention == "DOCX") {
                        e.preventDefault();
                        setApercuFichierPDF(CONFIG.URL + '/data/getPDF/' + fichier.fiId + "?auth=" + props.token)
                    }
                    if (fichier.fichiertype.ftExtention == "XLSX") {
                        e.preventDefault();
                        setApercuFichierPDF(CONFIG.URL + '/data/getPDF/' + fichier.fiId + "?auth=" + props.token)
                    }
                    /*
                    if (fichier.fichiertype.ftExtention == "PNG" || fichier.fichiertype.ftExtention == "JPG" || fichier.fichiertype.ftExtention == "JPEG") {
                        e.preventDefault();
                        setApercuFichierImage(fichier)
                    }*/
                }} >{fichier.fiNom}</a></td>
                <td className='hidden-md'>{fichier.fichiertype.ftNom}</td>
                <td className='hidden-xs'>{moment(Date.parse(fichier.fiCreation)).format("DD/MM/YYYY")}</td>
                <td className='hidden-md'>{fichier.fiDatePiece ? moment(Date.parse(fichier.fiDatePiece)).format("DD/MM/YYYY") : ''}</td>
                <td className='hidden-xs'>{fichier.tiers ? (fichier.tiers.ctEstPro ? fichier.tiers.ctIntitule : fichier.tiers.ctNom + ' ' + fichier.tiers.ctPrenom) : ''}</td>
                <td className='hidden-xs'>{fichier.fiNature}</td>
                <td className='hidden-md'>{fichier.fiReference}</td>
                <td className='hidden-md'><input readOnly={"readonly"} type="checkbox" checked={fichier.fiEstVisible}></input></td>

            </tr>
        )
    }
    function getDossier(element, niveau) {
        return <Fragment key={"DS" + element.dsId}>
            <tr className={dossierEnCours == element ? 'ligneSelect ligneEnCours' : ''} onMouseDown={(e) => trclick(element, null, e)} id={element.dsId} key={"DS" + element.dsId}>
                {props.modeSelection ? <td><input onChange={(e) => pcdSelectDossier(element, e.target.checked)} type="checkbox" checked={tblDossierSelect.indexOf(element) >= 0}></input></td> : null}
                <td style={{ paddingLeft: (!asChild(element) && niveau == 0) ? 20 : 40 * niveau - (asChild(element) ? 15 : 0) + 'px' }}>{asSousDossier(element)}<FcFolder /><span>{element.dsNom}</span> </td>
                {props.complet ?
                    <>
                        <td className='hidden-md'>{""}</td>
                        <td className='hidden-xs'>{""}</td>
                        <td className='hidden-md'>{""}</td>
                        <td className='hidden-xs'>{""}</td>
                        <td className='hidden-xs'>{""}</td>
                        <td className='hidden-md'>{""}</td>
                        <td className='hidden-md'>{""}</td>
                    </>
                    : null}
            </tr>
            {tblDossierCharge.indexOf(element.dsId) >= 0 ? element.sousdossiers.map((souselement, i) => {
                return (
                    getDossier(souselement, niveau + 1)
                )
            }) : null}
            {tblDossierCharge.indexOf(element.dsId) >= 0 && props.complet ? element.fichiers.map((souselement, i) => {
                if (props.filtreExtension && props.filtreExtension.length > 0 && props.filtreExtension.findIndex((e) => e == souselement.fichiertype.ftExtention) < 0) {
                    null
                } else {
                    return getFichier(souselement, niveau + 1)

                }


            }) : null}
        </Fragment>

    }

    function asChild(dossier) {

        if ((dossier.sousdossiers && dossier.sousdossiers.length > 0) || (props.complet && dossier.fichiers && dossier.fichiers.length > 0)) {
            return true;
        } else {
            return false;
        }
    }


    function asSousDossier(dossier) {
        if ((dossier.sousdossiers && dossier.sousdossiers.length > 0) || (props.complet && dossier.fichiers && dossier.fichiers.length > 0)) {
            return (<a href="#" onClick={(e) => chargeSousDossier(e, dossier)}>{tblDossierCharge.indexOf(dossier.dsId) >= 0 ? <MdExpandMore /> : <MdChevronRight />}</a>);
        }
    }

    function chargeSousDossier(e, dossier) {
        e.preventDefault();

        var index = tblDossierCharge.indexOf(dossier.dsId);
        if (index >= 0) {

            settblDossierCharge(tblDossierCharge.filter((ele, i) => i !== index))

            return;
        }
        settblDossierCharge([...tblDossierCharge, dossier.dsId])
        if (dossier.sousdossiers && dossier.sousdossiers.length > 0) {
            dossier.sousdossiers.map((element, i) => {
                getDataEnfant(element, i);
            })
        }
    }





    return (<div id="tahFichier" className="col-lg-12" key={"GED" + props.exid}>
        {enFetching ? <div>
            <Fetching></Fetching>
        </div> : null}
        <div className=' card shadow p-2 d-flex justify-content-between'>

            <h4 className="mx-2">Fichier local</h4>
            <button className='btn btn-primary ' onClick={() => (refFichier.current.click())}>Charger un fichier local</button>

        </div>
        <input style={{ visibility: 'hidden' }} ref={refFichier} id="fileItem" className="" type="file" onChange={(e) => setSelectedFile(e.target.files[0])} />

        <div className=' card shadow p-2 mt-2'>

            <h4 className="mx-2">Bibliothèque de l'expertise</h4>

            <div className='d-flex'>
                <button onClick={insertFile} style={{ display: (tblFichierSelect && tblFichierSelect.length > 0 ? '' : 'none') }} className='btn btn-primary my-1'>Insérer</button>
                <div style={{ display: ('none') }} className='col-md-4 col-sm-6 col-xs-12 mt-2 mx-2'>
                    <div className="form-check form-switch">
                        <input type="checkbox" className="form-check-input" onChange={(e) => setAvecTitre(e.target.checked)}
                            checked={avecTitre ? "checked" : ""}></input>   <label className="form-check-label">Avec titre</label>
                    </div>
                </div>
                <div className='d-flex mx-2'>
                    <div style={{ display: (tblFichierSelect && tblFichierSelect.length > 0 ? '' : 'none') }} className="form-check form-switch mt-3">
                        <input type="checkbox" className="form-check-input " onChange={(e) => setAvecTitre(e.target.checked)}
                            checked={avecTitre ? "checked" : ""}></input>   <label className="form-check-label">Avec les titres</label>
                    </div>
                    <div style={{ display: (tblFichierSelect && tblFichierSelect.length > 0 && avecTitre ? '' : 'none') }} className="form-check form-switch ">
                        <label className="">Style des titres</label>
                        <input type="text" className="form-control" onChange={(e) => setStyleTitre(e.target.value)}
                            value={StyleTitre}></input>
                    </div>
                </div>
            </div>

            <table className="table table-sm" id="main-table">

                <thead className="table-dark">
                    <tr>
                        {props.modeSelection ? <th style={{ width: '5%' }}><GrCheckboxSelected /></th> : null}
                        <th style={{ width: '30%' }}>Nom</th>
                        <th className='hidden-md' style={{ width: '15%' }}>Format</th>
                        <th className='hidden-xs' style={{ width: '10%' }}>Créé le</th>
                        <th className='hidden-md' style={{ width: '10%' }}>Date de pièce</th>
                        <th className='hidden-xs' style={{ width: '10%' }}>Provenance/Destinataire</th>
                        <th className='hidden-xs' style={{ width: '10%' }}>Type</th>
                        <th className='hidden-md' style={{ width: '10%' }}>Référence</th>
                        <th className='hidden-md' style={{ width: '5%' }}>Sur le <br /> rapport</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tblData.map((element, i) =>
                            getDossier(element, 0)


                        )
                    }

                </tbody>
            </table >
            {ApercuFichierPDF != '' ? <PdfViewer url={ApercuFichierPDF} onClose={() => setApercuFichierPDF('')}></PdfViewer> : null}


        </div>
    </div >

    )

}

